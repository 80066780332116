import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';

import { AccountService } from 'app/account/account.service';
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';
import { environment } from '../../../environments/environment';

declare const isEmpty: any;

@Injectable({ providedIn: 'root' })
export class SellerDocumentacaoService extends BaseService {
    private _apiSellerUrl: string = `${environment.apiSeller}`;
    private _domain: string = '/customers';


    constructor(private accountService: AccountService, private config: SettingsService) {
        super();
    }

    public getAll() {
        if (isEmpty(this.usuarioLogado)) {
            this.accountService.logout();
            return EMPTY;
        }

        return this.config.executeGet({ baseUrl: this._apiSellerUrl, url: this._domain });
    }

    public getDocumentation(buyerHoldingId: number) {
        return this.config.executeGet({ baseUrl: this._apiSellerUrl, url: `${this._domain}/${buyerHoldingId}/documentation` });
    }

    public updateBuyersLimit(buyerCompanyId: number, subscriptionStatus: number, limit: number) {
        const body = { subscriptionStatus, limit };
        return this.config.executePut({ baseUrl: this._apiSellerUrl, url: `${this._domain}/${buyerCompanyId}/update-limit`, body });
    }
}
